import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Redirect } from "react-router-dom";

import {
  SearchConsumer,
  SortOrdersProvider,
  getFiltersData,
  Loader,
  FilterOperator
} from "@deity/falcon-ecommerce-uikit";

import {
  ProductsList,
  Filters,
  BrandHeader,
  ProductListHeader,
  ButtonTypes,
  Button,
  FooterCategory
} from "benlux-ecommerce-ui";

import { CategoryProductsQuery } from "../../../queries/CategoryProductsQuery";
import { CMSBlockByIdQuery } from "../../../queries/CMSBlockByIdQuery";
import withProductListingConfig from "../../../components/withProductListingConfig";
import { KilibaCategoryViewTracker } from "../../../components/KilibaTracker/KilibaCategoryViewTracker";

const copy = item => item && JSON.parse(JSON.stringify(item));

const BrandPage = ({
  id,
  location,
  history,
  productListingConfig,
  staticContext
}) => {
  const query = new URLSearchParams(location.search);
  const [page, setPage] = useState(null);

  // Redirect ?page=1 to base url
  if (query && query.get("page")) {
    if (query.get("page") === "1") {
      staticContext.statusCode = 301;
      return (
        <Redirect
          to={{
            pathname: location.pathname
          }}
        />
      );
    }
  }

  if (!page) {
    if (query && query.get("page")) {
      try {
        setPage(parseInt(query.get("page")));
      } catch (err) {}
    } else {
      setPage(1);
    }
  }

  useEffect(() => {
    if (query && query.get("page")) {
      try {
        setPage(parseInt(query.get("page")));
        if (window) {
          window.scroll(0, 0);
        }
      } catch (err) {}
    } else {
      setPage(1);
    }
  }, [query]);

  return (
    <SearchConsumer>
      {({ state }) => (
        <CategoryProductsQuery
          variables={{
            pagination: {
              perPage: productListingConfig.perPage,
              page: page
            },
            categoryId: id,
            sort: state.sort,
            filters: copy(state.filters),
            path: location.pathname
          }}
        >
          {({ category, fetchMore, networkStatus, loading }) => {
            if (!category && loading) {
              return <Loader />;
            }
            const { name, products, seo, urlPath } = category;
            const { pagination, items, aggregations, priceLimits } = products;
            const filtersData = getFiltersData(state.filters, aggregations);
            const title = seo.title || name;
            const description = seo.description || category.description || "";

            return (
              <>
                <Helmet>
                  <title>{title}</title>
                  {/* TODO: dynamic base path */}
                  <link
                    rel="canonical"
                    href={
                      "https://www.benlux.fr" +
                      urlPath +
                      (page > 1 ? "?page=" + page : "")
                    }
                  />
                  <meta name="description" content={description} />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content={title + " ‣ BENLUX"} />
                  {category.image && (
                    <meta property="og:image" content={category.image} />
                  )}
                  <meta property="og:description" content={description} />
                  <meta
                    property="og:url"
                    content={"https://www.benlux.fr" + urlPath}
                  />
                  <meta name="twitter:card" content="summary_large_image" />
                  <meta property="twitter:domain" content="benlux.fr" />
                  <meta
                    property="twitter:url"
                    content={"https://www.benlux.fr" + urlPath}
                  />
                  <meta name="twitter:title" content={title + " ‣ BENLUX"} />
                  <meta name="twitter:description" content={description} />
                  {category.image && (
                    <meta name="twitter:image" content={category.image} />
                  )}
                  <meta name="page-loaded" content="true" />
                  {pagination.currentPage > 1 && (
                    <link
                      rel={"prev"}
                      href={
                        location.pathname +
                        (pagination.currentPage > 2
                          ? "?page=" + (pagination.currentPage - 1)
                          : "")
                      }
                    />
                  )}
                  {pagination.currentPage < pagination.nextPage && (
                    <link
                      rel={"next"}
                      href={location.pathname + "?page=" + pagination.nextPage}
                    />
                  )}
                </Helmet>
                <KilibaCategoryViewTracker categoryId={id} />
                {category.landingPage ? (
                  <CMSBlockByIdQuery variables={{ id: category.landingPage }}>
                    {({ cmsBlockById }) => {
                      if (!cmsBlockById && loading) {
                        return <Loader />;
                      }
                      const contents = JSON.parse(cmsBlockById.content);
                      return (
                        <BrandHeader brand={category} contents={contents} />
                      );
                    }}
                  </CMSBlockByIdQuery>
                ) : (
                  <BrandHeader brand={category} />
                )}
                {pagination.totalItems === 0 ? (
                  <div
                    style={{
                      maxWidth: "1200px",
                      margin: "96px auto",
                      textAlign: "center"
                    }}
                  >
                    <h2>
                      Cette marque est uniquement disponible dans notre magasin
                      BENLUX Paris
                    </h2>
                    <p>
                      Venez nous rendre visite et profiter d'une expérience de
                      shopping unique !
                    </p>
                    <Button
                      text={"Découvrir le magasin"}
                      type={ButtonTypes.Tertiary}
                      as={"link"}
                      href={"/magasin-paris"}
                    />
                  </div>
                ) : (
                  <>
                    <SearchConsumer>
                      {({
                        state: { filters },
                        setFilter,
                        removeFilters,
                        removeFilter
                      }) => {
                        return (
                          <ProductListHeader
                            breadcrumbs={category.breadcrumbs}
                            brandName={category.name}
                            clearFilter={removeFilters}
                          >
                            <SortOrdersProvider>
                              {sortOrdersProps => (
                                <Filters
                                  sortOrdersProps={sortOrdersProps}
                                  excludedFilters={["manufacturer"]}
                                  onClickRemoveFilters
                                  data={filtersData}
                                  priceLimits={priceLimits}
                                  onChangeOptions={(field, x) => {
                                    setFilter(
                                      field,
                                      x ? [x] : [],
                                      FilterOperator.inSet
                                    );
                                  }}
                                  removeFilter={field => {
                                    removeFilter(field);
                                  }}
                                  onChangePrice={(field, x) => {
                                    setFilter("price", x ? [x.join("-")] : []);
                                  }}
                                />
                              )}
                            </SortOrdersProvider>
                          </ProductListHeader>
                        );
                      }}
                    </SearchConsumer>
                    <ProductsList
                      categoryName={category.name}
                      products={items}
                      loading={networkStatus === 3 || loading}
                      pagination={pagination}
                      query={query}
                      fetchMore={fetchMore}
                    />
                    <FooterCategory category={category} links={[]} />
                  </>
                )}
              </>
            );
          }}
        </CategoryProductsQuery>
      )}
    </SearchConsumer>
  );
};

BrandPage.propTypes = {
  id: PropTypes.string.isRequired
};

export default withProductListingConfig(BrandPage);
